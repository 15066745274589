<template>
    <div style="height: 100%;">
        <router-view :class="classValue()" :key="$route.name"></router-view>
    </div>
</template>

<script>
   
    export default {
        components:{
            
        },
        data() {
            return {
                
            }
        },
        created() {
            
        },
       
        methods:{
            classValue() {
                let classVal = []
                if(this.$store.getters.currentPageVersion === 'Horizontal') {
                    classVal.push('h-child-box')
                }else {
                    classVal.push('v-child-box')
                }
                if(this.$route.name === "MineIndex") {
                    classVal.push('index-active')
                }
                return classVal
            }
            
        }
    }
</script>

<style scoped>
    .v-child-box,
    .h-child-box {
        height: 100%;
        padding: 12px;
    }
    .v-child-box {
        padding: 22px;
    }
    .index-active {
        padding: 0;
    }
</style>